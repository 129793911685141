import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";
import SportSingleSelect from "./form/sport-single-select-search";
import { Form as BSForm } from "react-bootstrap";

import UserSingleSelect from "./form/user-single-select";
import _ from "lodash";
import usePPPUsers from "../hooks/users/usePPPUsers";
import { Sports } from "../types/form/sports.enum";

interface Props {
  sport: { value: Sports; label: string } | null;
  type: any;
  user: any;
  setSport: React.Dispatch<{ value: Sports; label: string } | null>;
  setUser: React.Dispatch<any>;
  setType: React.Dispatch<any>;
}

const ProjectFilter = ({
  sport,
  setSport,
  user,
  setUser,
  type,
  setType,
}: Props) => {
  const { pppUsersQuery } = usePPPUsers();

  const handleReset = () => {
    setSport(null);
    setUser(null);
    setType("allProjects");
  };
  const changeType = (e: any) => {
    setType(e.target.value);
  };
  return (
    <div className="card">
      <div className="card-header bg-secondary">
        <h2 className="text-white h3 mb-1">
          <FormattedMessage
            id="filter_projects"
            defaultMessage="Filter Projects"
          />
        </h2>
      </div>
      <div className="card-body">
        <form>
          <div className="row">
            <div className="col-6">
              <BSForm.Group className="mb-3">
                <BSForm.Label>
                  {" "}
                  <FormattedMessage id="sport" defaultMessage="Sport" />
                </BSForm.Label>
                <SportSingleSelect
                  name="sport"
                  selectAllEnabled={true}
                  onChange={setSport}
                  value={sport}
                ></SportSingleSelect>
              </BSForm.Group>
            </div>
            <div className="col-6">
              <BSForm.Group className="mb-3">
                <BSForm.Label>
                  {" "}
                  <FormattedMessage id="user" defaultMessage="User" />
                </BSForm.Label>
                <UserSingleSelect
                  name="user"
                  selectAllEnabled={true}
                  setUser={setUser}
                  value={user}
                  usersList={pppUsersQuery.data || []}
                  loading={pppUsersQuery.isLoading}
                  disabled={pppUsersQuery.isLoading}
                ></UserSingleSelect>
              </BSForm.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9 col-sm-12 mt-3">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="types"
                  id="allProjects"
                  value="allProjects"
                  checked={type == "allProjects"}
                  onClick={changeType}
                />
                <label className="form-check-label" htmlFor="allProjects">
                  Sport Wales Projects
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="types"
                  id="sportWalesWIPSProjects"
                  value="sportWalesWIPSProjects"
                  checked={type == "sportWalesWIPSProjects"}
                  onClick={changeType}
                />
                <label
                  className="form-check-label"
                  htmlFor="sportWalesWIPSProjects"
                >
                  Sport Wales & WIPS supported projects
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="types"
                  id="WIPSProjects"
                  value="WIPSProjects"
                  checked={type == "WIPSProjects"}
                  onClick={changeType}
                />
                <label className="form-check-label" htmlFor="WIPSProjects">
                  WIPS Projects
                </label>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 mt-3 d-flex">
              <button
                type="button"
                className="btn btn-outline-secondary ms-auto"
                onClick={handleReset}
              >
                <FormattedMessage
                  id="reset_filters"
                  defaultMessage="Reset Filters"
                />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ProjectFilter;
