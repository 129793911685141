import { User } from "@microsoft/microsoft-graph-types-beta";
import React from "react";
import Select, { ActionMeta, SingleValue } from "react-select";
import { components } from "../../types/projects";

interface Props {
  name: string;
  value: components["schemas"]["Person"] | undefined;
  selectAllEnabled?: boolean;
  usersList: User[];
  loading: boolean;
  disabled: boolean;
  setUser: React.Dispatch<any>;
}

const UserSingleSelect = ({
  setUser,
  value,
  usersList,
  loading,
  disabled,
}: Props) => {
  const selected = { value: value?.userId, label: value?.name };

  if (!usersList) {
    return <span>Loading</span>;
  }
  // options come from groups in Azure
  const optionsArray = usersList
    .filter((user) => !!user.displayName)
    .map((user) => {
      return { value: user.id, label: user.displayName };
    });
  return (
    <Select
      isSearchable
      isClearable
      value={selected}
      options={optionsArray as any}
      isLoading={loading}
      isDisabled={disabled}
      onChange={(selectedOption) => {
        const user: components["schemas"]["Person"] = {
          name: (selectedOption as any)?.label,
          userId: (selectedOption as any)?.id,
        };
        setUser(selectedOption ? user : null);
      }}
    />
  );
};

export default UserSingleSelect;
