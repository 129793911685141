const projectStatusArray = [
  "Evaluation - Stage 1",
  "Evaluation - Stage 2",
  "Confirmation Of Delivery Team",
  "Planning",
  "Delivery",
  "Review & Learn",
  "Completed",
];

export default projectStatusArray;
