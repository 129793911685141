import React from "react";
import { Sports } from "../../types/form/sports.enum";
import Select from "react-select";
import _ from "lodash";

interface Props {
  name: string;
  value: Sports | string | undefined;
  selectAllEnabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SportSingleSelect = ({ onChange, value }: Props) => {
  const sports = (Object.keys(Sports) as Array<keyof typeof Sports>).map(
    (val) => ({
      value: Sports[val],
      label: Sports[val],
    })
  );

  return (
    <Select
      isSearchable
      value={value}
      isClearable
      options={_.orderBy(sports, "value", "asc")}
      onChange={onChange}
    />
  );
};

export default SportSingleSelect;
