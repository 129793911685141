import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import { getQueryParams, setQueryParams } from "react-use-query-param-string";

import { Accordion } from "react-bootstrap";

import {
  deleteProject,
  getSubmittedProposals,
  updateProject,
} from "../services/getRequest";
import Layout from "../components/layout";
import { Spinner } from "react-bootstrap";
import _ from "lodash";
import ProjectsOverview from "../components/project/projects-overview";
import { components } from "../types/projects";
import ProjectFilter from "../components/project-filter";
import ConfirmDeleteProjectModal from "../components/modal/confirm-delete-project-modal";
import SwToast from "../components/toast/sw-toast";
import projectStatusArray from "../const/projectStatusArray";
import { Sports } from "../types/form/sports.enum";
import includesSport from "../utils/projectFilters/includesSport";
import allStatuses from "../const/allStatuses";
import WIPSStatuses from "../const/WIPSStatuses";

interface Props {
  location: Location;
}

const Projects = ({ location }: Props) => {
  const params = getQueryParams();
  const searchParams = params.status;
  const searchTypeParams = params.type;

  const intl = useIntl();
  const [status, setStatus] = useState<string>();

  const [allProjects, setAllProjects] = useState<
    components["schemas"]["Project"][]
  >([]);
  const [allWIPS, setAllWIPS] = useState<components["schemas"]["Project"][]>(
    []
  );
  const [allWIPSSportWales, setAllWIPSSportWalesS] = useState<
    components["schemas"]["Project"][]
  >([]);

  const [loading, setLoading] = useState(true);
  const [sport, setSport] = useState<{ value: Sports; label: string } | null>(
    null
  );
  // filter by actual name as ids are not historically on all projects
  const [user, setUser] = useState<components["schemas"]["Person"] | null>(
    null
  );

  const [showModal, setShowModal] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailToast, setShowFailToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [currentProject, setCurrentProject] =
    useState<components["schemas"]["Project"]>();

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = (project: components["schemas"]["Project"]) => {
    setCurrentProject(project);
    setShowModal(true);
  };

  const handleDelete = () => {
    setShowModal(false);
    if (currentProject) {
      handleDeleteProject(currentProject);
    }
  };

  const [type, setType] = useState([]);

  const handleDeleteProject = (project: components["schemas"]["Project"]) => {
    if (project.id) {
      deleteProject(project.id)
        .then((deletedProject: { deletedId: string }) => {
          let newProjects = allProjects?.filter(
            (project) => project.id !== deletedProject.deletedId
          );
          setAllProjects(newProjects);
          setShowSuccessToast(true);
        })
        .catch((error) => {
          setErrorMessage(error);
          setShowFailToast(true);
        });
    }
  };

  const changeType = (e) => {
    setType(e);
    setQueryParams({ ...params, type: e });
  };

  useEffect(() => {
    setType(searchTypeParams && searchTypeParams);
  }, [searchParams]);

  useEffect(() => {
    // setStatus(searchParams.has("status") && searchParams.get("status"));
    // searchParams.set("status", "Test")
    setStatus(searchParams && searchParams);
    if (loading) {
      getSubmittedProposals().then(
        (value: { projects: components["schemas"]["Project"][] }) => {
          const { projects, WIPS, WIPSAndSportWales } = value;
          setAllProjects(_.union(projects, allStatuses));
          setAllWIPS(_.union(WIPS, WIPSStatuses));
          setAllWIPSSportWalesS(_.union(WIPSAndSportWales, allStatuses));
          setLoading(false);
        },
        (reason) => {
          console.error(reason);
          setLoading(false);
        }
      );
    } else {
      if (status) {
        const element = document.getElementById(status);
        if (element) {
          element.scrollIntoView(true);
        }
      }
    }
  }, [loading]);

  const handeChangeStatus = (
    projectId: string,
    status: components["schemas"]["Project"]["status"]
  ) => {
    const project = { status: status };
    updateProject(projectId, project)
      .then((data: components["schemas"]["Project"]) => {
        if (data) {
          setLoading(true);
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <Layout mainClassName="container-fluid">
      <div className="row">
        <h1 className="block-title">
          <FormattedMessage
            id="my_proposals_and_projects"
            defaultMessage="My Proposals and Projects"
          />
        </h1>
      </div>
      {loading ? (
        <div className="row">
          <div className="col">
            <h2 className="anchor-title">
              <FormattedMessage id="loading" />{" "}
              <FormattedMessage id="my_proposals_and_projects" />
            </h2>
            <Spinner animation="border" variant="secondary" role="status">
              <span className="visually-hidden">
                <FormattedMessage id="loading" />
              </span>
            </Spinner>
          </div>
        </div>
      ) : (
        <div>
          <ProjectFilter
            sport={sport}
            setSport={setSport}
            user={user}
            setUser={setUser}
            type={type}
            setType={changeType}
          />
          <Accordion
            defaultActiveKey={type}
            activeKey={type}
            onSelect={(eventKey: string | string[] | null, event: Object) => {
              setType(eventKey);
              setQueryParams({ ...params, type: eventKey });
            }}
          >
            {JSON.stringify(allProjects) !== "{}" ? (
              <Accordion.Item
                id="allProjects"
                eventKey="allProjects"
                key="allProjects"
              >
                <Accordion.Header>
                  <h2>Sport Wales projects</h2>
                </Accordion.Header>
                <Accordion.Body>
                  <Accordion
                    flush
                    className="mb-3"
                    onSelect={(
                      eventKey: string | string[] | null,
                      event: Object
                    ) => {
                      setQueryParams({ ...params, status: eventKey });
                    }}
                    defaultActiveKey={status}
                  >
                    {Object.entries(
                      _.groupBy(
                        _.sortBy(allProjects, function (item) {
                          return projectStatusArray.indexOf(item.status);
                        }),
                        "status"
                      )
                    ).map(([key, value, index]) => {
                      let projects = value.filter(
                        (p: components["schemas"]["Project"]) => {
                          if (p.sport == null && p.submittedBy == null) {
                            return false;
                          }
                          if (sport == null && user == null) {
                            return true;
                          } else {
                            if (
                              sport == null &&
                              (_.find(p.lead, { name: user?.name }) ||
                                _.find(p.practitioner, { name: user?.name }))
                            ) {
                              return true;
                            }
                            if (
                              user == null &&
                              sport &&
                              includesSport(p, sport.value)
                            ) {
                              return true;
                            }
                            if (
                              (_.find(p.lead, { name: user?.name }) ||
                                _.find(p.practitioner, { name: user?.name })) &&
                              p.sport == sport?.value
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          }
                        }
                      );
                      return (
                        <Accordion.Item
                          id={key}
                          eventKey={key}
                          key={key}
                          className={`accordion-item--${_.kebabCase(key)}`}
                        >
                          <Accordion.Header className="ms-2">
                            <p className="mb-0 ">
                              {`${key}`}{" "}
                              <small className="ps-5">{`${
                                projects.length
                              } ${intl.formatMessage({
                                id: "project.requests",
                                defaultMessage: "Requests",
                              })}`}</small>
                            </p>
                          </Accordion.Header>
                          {projects.length > 0 && (
                            <Accordion.Body
                              className={`accordion-border accordion-border--${_.kebabCase(
                                key
                              )} position-relative`}
                            >
                              <ProjectsOverview
                                projects={projects}
                                onChangeStatus={handeChangeStatus}
                                commentSelected={
                                  location.state
                                    ? location.state.commentId
                                    : null
                                }
                                deliverableSelected={
                                  location.state
                                    ? location.state.deliverableId
                                    : null
                                }
                                handleDeleteProject={handleShowModal}
                              />
                            </Accordion.Body>
                          )}
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <div className="row" data-id="projects"></div>
            )}
            {JSON.stringify(allWIPSSportWales) !== "{}" ? (
              <Accordion.Item
                id="sportWalesWIPSProjects"
                eventKey="sportWalesWIPSProjects"
                key="sportWalesWIPSProjects"
              >
                <Accordion.Header>
                  <h2>Sport Wales & WIPS supported projects</h2>
                </Accordion.Header>
                <Accordion.Body>
                  <Accordion
                    flush
                    className="mb-3"
                    onSelect={(
                      eventKey: string | string[] | null,
                      event: Object
                    ) => {
                      setQueryParams({ ...params, status: eventKey });
                    }}
                    defaultActiveKey={status}
                  >
                    {Object.entries(
                      _.groupBy(
                        _.sortBy(allWIPSSportWales, function (item) {
                          return projectStatusArray.indexOf(item.status);
                        }),
                        "status"
                      )
                    ).map(([key, value, index]) => {
                      let projects = value.filter((p) => {
                        if (p.sport == null && p.submittedBy == null) {
                          return false;
                        }
                        if (sport == null && user == null) {
                          return true;
                        } else {
                          if (
                            sport == null &&
                            (_.find(p.lead, { name: user?.name }) ||
                              _.find(p.practitioner, { name: user?.name }))
                          ) {
                            return true;
                          }
                          if (
                            user == null &&
                            sport &&
                            includesSport(p, sport.value)
                          ) {
                            return true;
                          }
                          if (
                            (_.find(p.lead, { name: user }) ||
                              _.find(p.practitioner, { name: user?.name })) &&
                            sport &&
                            includesSport(p, sport.value)
                          ) {
                            return true;
                          } else {
                            false;
                          }
                        }
                      });
                      return (
                        <Accordion.Item
                          id={key}
                          eventKey={key}
                          key={key}
                          className={`accordion-item--${_.kebabCase(key)}`}
                        >
                          <Accordion.Header className="ms-2">
                            <p className="mb-0 ">
                              {`${key}`}{" "}
                              <small className="ps-5">{`${
                                projects.length
                              } ${intl.formatMessage({
                                id: "project.requests",
                                defaultMessage: "Requests",
                              })}`}</small>
                            </p>
                          </Accordion.Header>
                          {projects.length > 0 && (
                            <Accordion.Body
                              className={`accordion-border accordion-border--${_.kebabCase(
                                key
                              )} position-relative p-0`}
                            >
                              <ProjectsOverview
                                projects={projects}
                                onChangeStatus={handeChangeStatus}
                                commentSelected={
                                  location.state
                                    ? location.state.commentId
                                    : null
                                }
                                deliverableSelected={
                                  location.state
                                    ? location.state.deliverableId
                                    : null
                                }
                                handleDeleteProject={handleShowModal}
                              />
                            </Accordion.Body>
                          )}
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <div className="row" data-id="projects"></div>
            )}
            {JSON.stringify(allWIPS) !== "{}" ? (
              <Accordion.Item
                id="WIPSProjects"
                eventKey="WIPSProjects"
                key="WIPSProjects"
              >
                <Accordion.Header>
                  <h2>WIPS Projects</h2>
                </Accordion.Header>
                <Accordion.Body>
                  <Accordion
                    flush
                    className="mb-3"
                    onSelect={(
                      eventKey: string | string[] | null,
                      event: Object
                    ) => {
                      setQueryParams({ ...params, status: eventKey });
                    }}
                    defaultActiveKey={status}
                  >
                    {Object.entries(
                      _.groupBy(
                        _.sortBy(allWIPS, function (item) {
                          return projectStatusArray.indexOf(item.status);
                        }),
                        "status"
                      )
                    ).map(([key, value]) => {
                      let projects = value.filter((p) => {
                        if (p.sport == null && p.submittedBy == null) {
                          return false;
                        }
                        if (sport == null && user == null) {
                          return true;
                        } else {
                          if (
                            sport == null &&
                            (_.find(p.lead, { name: user?.name }) ||
                              _.find(p.practitioner, { name: user?.name }))
                          ) {
                            return true;
                          }
                          if (
                            user == null &&
                            sport &&
                            includesSport(p, sport.value)
                          ) {
                            return true;
                          }
                          if (
                            (_.find(p.lead, { name: user?.name }) ||
                              _.find(p.practitioner, { name: user?.name })) &&
                            sport &&
                            includesSport(p, sport.value)
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        }
                      });
                      return (
                        <Accordion.Item
                          id={key}
                          eventKey={key}
                          key={key}
                          className={`accordion-item--${_.kebabCase(key)}`}
                        >
                          <Accordion.Header className="ms-2">
                            <p className="mb-0 ">
                              {`${key}`}{" "}
                              <small className="ps-5">{`${
                                projects.length
                              } ${intl.formatMessage({
                                id: "project.requests",
                                defaultMessage: "Requests",
                              })}`}</small>
                            </p>
                          </Accordion.Header>
                          {projects.length > 0 && (
                            <Accordion.Body
                              className={`accordion-border accordion-border--${_.kebabCase(
                                key
                              )} position-relative`}
                            >
                              <ProjectsOverview
                                projects={projects}
                                onChangeStatus={handeChangeStatus}
                                commentSelected={
                                  location.state
                                    ? location.state.commentId
                                    : null
                                }
                                deliverableSelected={
                                  location.state
                                    ? location.state.deliverableId
                                    : null
                                }
                                handleDeleteProject={handleShowModal}
                              />
                            </Accordion.Body>
                          )}
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <div className="row" data-id="projects"></div>
            )}
          </Accordion>
        </div>
      )}

      {
        <div className="p-2">
          <p>
            Want to to generate a report? <a href="/report">Generate report</a>
          </p>
        </div>
      }

      <ConfirmDeleteProjectModal
        show={showModal}
        project={currentProject}
        onHide={handleCloseModal}
        handleClose={handleCloseModal}
        handleDelete={handleDelete}
      ></ConfirmDeleteProjectModal>
      <SwToast
        show={showFailToast}
        onClose={() => setShowFailToast(false)}
        header={intl.formatMessage({
          id: "project.delete.fail",
          defaultMessage: "Delete Failed",
        })}
        body={
          errorMessage
            ? `${(errorMessage as any)?.name}: ${
                (errorMessage as any)?.message
              }`
            : ""
        }
      ></SwToast>
      <SwToast
        show={showSuccessToast}
        onClose={() => setShowSuccessToast(false)}
        header={intl.formatMessage({
          id: "project.delete.success",
          defaultMessage: "Project Deleted",
        })}
        body={intl.formatMessage({
          id: "project.delete.success.message",
          defaultMessage: "Project was successfully deleted",
        })}
      ></SwToast>
    </Layout>
  );
};

export default Projects;
