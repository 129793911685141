const allStatuses = [
  { status: "Evaluation - Stage 1", sport: null, submittedBy: null },
  { status: "Evaluation - Stage 2", sport: null, submittedBy: null },
  { status: "Confirmation Of Delivery Team", sport: null, submittedBy: null },
  { status: "Planning", sport: null, submittedBy: null },
  { status: "Delivery", sport: null, submittedBy: null },
  { status: "Review & Learn", sport: null, submittedBy: null },
  { status: "Completed", sport: null, submittedBy: null },
];

export default allStatuses;
