import { Sports } from "../../types/form/sports.enum";
import { components } from "../../types/projects";

const includesSport = (
  project: components["schemas"]["Project"],
  sport: Sports
) => {
  return sport && project.sport?.includes(sport);
};

export default includesSport;
