export enum Sports {
  CYCLING = "Cycling",
  GYMNASTICS = "Gymnastics",
  ATHLETICS = "Athletics",
  SWIMMING = "Swimming",
  BOXING = "Boxing",
  FOOTBALL = "Football",
  WEIGHTLIFTING = "Weightlifting",
  DSW = "DSW",
  CANOEING = "Canoeing",
  ROWING = "Rowing",
  GOLF = "Golf",
  HOCKEY = "Hockey",
  CRICKET = "Cricket",
  SAILING = "Sailing",
  NETBALL = "Netball",
  JUDO = "Judo",
  SHOOTING = "Shooting",
  CGCW = "CGCW",
  TRIATHLON = "Triathlon",
  RUGBY_UNION = "Rugby Union",
  SQUASH = "Squash",
  BADMINTON = "Badminton",
  TABLE_TENNIS = "Table Tennis",
  BOWLS = "Bowls",
  TENNIS = "Tennis",
  SNOWSPORT = "Snow-sports",
  SNOOKER = "Snooker",
  FENCING = "Fencing",
  WRESTLING = "Wrestling",
  ANGLING = "Angling",
  ARCHERY = "Archery",
  RUGBY_LEAGUE = "Rugby League",
  MOTORCYCLE = "Motor Cycling",
  POOL = "Pool",
  SURF_LIFE = "Surf Life",
  CURLING = "Curling",
  ORIENTEERING = "Orienteering",
  LACROSSE = "Lacrosse",
  VOLLEYBALL = "Volleyball",
  TENPIN_BOWLS = "Tenpin Bowls",
  WSA = "WSA",
  BASKETBALL = "Basketball",
  DANCE = "Dance",
  KARATE = "Karate",
  TUG_OF_WAR = "Tug of War",
}


