import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";
import { Modal, Button } from "react-bootstrap";
import { components } from "../../types/projects";

interface Props {
  show: boolean;
  project: components["schemas"]["Project"] | undefined;
  onHide: () => void;
  handleClose: () => void;
  handleDelete: (subitem: components["schemas"]["Project"]) => void;
}

const ConfirmDeleteProjectModal = (props: Props) => {
  const { show, project, onHide, handleClose, handleDelete } = props;

  const onDelete = () => {
    if (project) {
      handleDelete(project);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage
            id="project.delete.modal_header"
            defaultMessage="Delete Project {title}"
            values={{ title: project ? project.title : "" }}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage
          id="project.delete.modal_body"
          defaultMessage="Are you sure that you want to delete this project? This action cannot
          be reversed"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          <FormattedMessage
            id="project.delete.cancel"
            defaultMessage="Cancel"
          />
        </Button>
        <Button variant="primary" onClick={onDelete}>
          <FormattedMessage
            id="project.delete.delete_project"
            defaultMessage="Delete Project"
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDeleteProjectModal;
